

.nav-menu{
    display: flex;
}

.btn-logout{
        font-size: 1rem;
    font-weight: 500;
    padding: 0.5rem 1rem;
    border: 1px solid #fff;
    border-radius: 5px;
    background: transparent;
    color: #fff;
    cursor: pointer;
    transition: 0.3s;
    
}

.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;
}

.header-bg{
    background-color: rgba(0, 0, 0, 0.85);
    transition: 1s;
}

.nav-menu li{
    list-style: none;
    padding: 0 1rem;
}

.nav-menu li a{
    font-size: 1.2rem;
    font-weight: 500;
}

.hamburger{
    display: none;
}

@media screen and (max-width:1040px){
    .nav-menu{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background: rgba(0, 0,0, 0.9);
        position: absolute;
        top: 0;
        left: -100%;
        z-index: -3;
        transition: 0.3s;
    }

    .nav-menu.active{
        left: 0;
    }
    .nav menu li{
        padding: 1rem 0;
    }

    .nav-menu li a{
        font-size: 2rem;
    }

    .hamburger{
        display: initial;
    }
}