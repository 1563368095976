.container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    max-width: 960px;
    margin: 60px auto;
  }

  *, *::before, *::after {
  box-sizing: border-box;
}


  @media (max-width: 768px) {
    .container {
        grid-template-columns: 1fr;
        margin: 30px auto;
    }

    .content, .sidebar {
        padding: 0;
          overflow: hidden;
    }

    .sidebar form {
        margin-top: 20px;
/* make object move left to make them in order of list */
        transform: translateX(-7%);
        width: 80%;        /* decrease the width to 80% */
        margin-left: auto; /* centering the form horizontally */
        margin-right: auto;
    }

    .content {
        order: 2;  /* on mobile, the list (.content) will be below */
    }

    .sidebar {
        order: 1;  /* on mobile, the form (.sidebar) will be on top */
    }

    .transactions {
      width: 80%;         /* decrease the width to 80% for the list */
      margin-left: auto;  /* centering the list horizontally */
      margin-right: auto;
  }

}


  .content {
    padding-right: 30px;
  }
  .sidebar {
    padding-left: 30px;
  }
  .sidebar form {
    padding: 20px;
    background: rgb(29,161,242);
    border-radius: 10px;
  }
  .sidebar input, .sidebar select {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 8px;
    box-sizing: border-box;
    border: 0;
    border-radius: 4px;
    color: #555;
    font-size: 1em;
  }
  .sidebar label {
    margin: 0 auto 20px;
    display: block;
    color: #fff;
  }
  .sidebar button {
    color: #fff;
    border: 2px solid #fff;
    padding: 6px 12px;
    background-color: transparent;
    font-size: 1em;
    border-radius: 4px;
    cursor: pointer;
    display: block;
    width: 100%;
  }
  .sidebar h3 {
    color: rgb(29,161,242);
    margin-bottom: 20px;
  }
  .sidebar aside {
    margin-top: 40px;
    color: #555;
  }
  .sidebar aside li {
    margin: 10px;
  }
  
  /* transactions */
  .transactions li {
    margin: 20px auto;
    border: 1px solid #f2f2f2;
    box-shadow: 3px 3px 5px rgba(50,50,50,0.1);
    padding: 20px;
    display: flex;
    
    align-items: center;
    position: relative;
    overflow: hidden;
    border-left: 4px solid rgb(29,161,242);
  }
  .transactions .name {
    color: #f2f2f2;
    font-size: 1.3em;
    margin-right: 40px;
  }
  .transactions .amount {
    margin-left: auto;
    margin-right: 40px;
    color: #f2f2f2;
    font-weight: bold;
    font-size: 1.6em;
  }
  .transactions button {
    position: absolute;
    top: 0;
    right:0;
    background: #ddd;
    color: #777;
    border: none;
    padding: 12px 8px;
    text-align: center;
    line-height: 0;
    font-size: 0.9em;
    cursor: pointer;
  }

  /* Home.module.css */
.scored {
    color: green;
}

.notScored {
    color: red;
}
