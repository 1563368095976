.about{
    width: 100%;
    margin: 3rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
}
.about .left{
    text-align: center;
    margin: auto;
    padding: 1rem;
    max-width: 500px;
}
.about .left p{
    margin: 1rem 0;
}

.about .right{
    max-width: 900px;
}
.right .img-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Changed to 2 columns */
    position: relative;
    align-items: center;
    text-align: center;
    gap: 20px; /* Added gap for spacing between images */
}

.right .img{
    max-width: 100%;
    border: 1px solid #333;
}

.right .top{
    grid-column: 1; /* Adjusted to occupy the first column */
    z-index: 6;
    transform: scale(1.5);
}

.right .bottom{
    grid-column: 2; /* Adjusted to occupy the second column */
    z-index: 5;
    transform: scale(0.9);
}

@media screen and (max-width: 640px){
    .right .bottom{

        transform: scale(0.9);
    }

    .right .top{
        grid-column: 1; /* Adjusted to occupy the first column */
        z-index: 6;
        transform: scale(1);
    }
}