.mask {
    width: 100%;
    height: 100vh; 
    position: relative;
    overflow: hidden; /* Ensure that image doesn't overflow the mask */
}

.intro-img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    
    
}

.btn-small {
    font-size: 0.8rem; /* Adjust as needed */
}

.mask::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6); /* Adding opacity directly to the background color */
}

.hero {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; 
    width: 100%;
    position: relative;
}

.hero .content {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
    display: flex;
    flex-direction: column; 
    align-items: center;    
    justify-content: center;
}


.hero .content h1 h2{
    font-size: 4rem;
    padding: 0.6rem 0 1.5rem;
}

.hero .content p{
    font-size: 1.4rem;
    font-weight: 200;
    text-transform: uppercase;
}
.content .btn{
    margin: 1rem 0.2rem 2rem 0.2rem;
}
.content div {
    display: inline-block;  
    margin: 1rem 0.2rem;    
 }

 @media screen and (max-width: 640px){
    .hero .content h1 h2{
        font-size: 3rem;
    }
    .hero .content p{
        font-size: 1.4rem;
    }
}
 
 