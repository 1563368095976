*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.wrapper{
display: flex;
justify-content: center;
align-items: center;
min-height: 100vh;
background: url('../assets/auth.jpg') no-repeat;
background-size: cover;
background-position: center;
}
.wrapper1{
width: 420px;
background: transparent;
border: 2px solid rgba(255,255,255,0.2);
backdrop-filter: blur(20px);
box-shadow: 0 0 10px rgba(0,0,0,0.2);
color: #fff;
border-radius: 10px;
padding: 30px 40px;
}

.wrapper1 h1{
font-size: 36px;
text-align: center;
}
.wrapper1 .input-box{
position: relative;
width: 100%;
height: 50px;
margin: 30px 0;
}

.input-box input{
width: 100%;
height: 100%;
background: transparent;
border: none;
outline: none;
border: 2px solid rgba(255,255,255,0.2);
border-radius: 40px;
font-size: 16px;
color: #fff;
padding: 20px 45px 20px 20px;
}

.input-box input::placeholder{
color:#fff
}

.input-box i{
position: absolute;
right: 20px;
top: 50%;
transform: translateY(-50%);
font-size: 22px;
}

.wrapper1 .remember-forgot{
display: flex;
justify-content: space-between;
font-size: 14.5px;
margin: -15px 0 15px;
}
.remember-forgot label input{
accent-color: #fff;
margin-right: 3px;
}

.remember-forgot a{
color: #fff;

}

.remember-forgot a:hover{
text-decoration: underline;
color: #fff;
}

.wrapper1 .btn{
width: 100%;
height: 45px;
background: #fff;
border: none;
outline: none;
border-radius: 40px;
box-shadow: 0 0 10px rgba(0,0,0,0.1);
cursor: pointer;
font-size: 16px;
color: #333;
font-weight: 600;
}
.wrapper1 .register-link{
font-size: 14.5px;
text-align: center;
margin: 20px 0 15px
}

.register-link a p{
color: #fff;
text-decoration: none;
font-weight: 600;
}

.register-link p a:hover{
text-decoration: underline;
color: #fff;
}











/* .signup-form {
    max-width: 360px;
    padding: 20px;
  }
  .signup-form label {
    display: block;
    margin: 30px auto;
  }
  .signup-form span {
    display: block;
    margin-bottom: 6px;
  }
  .signup-form input {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
  } */